export const MentorsList = [
    {
        name: 'Megan',
        color: 'green',
        experience: 'Graphic/visual/brand design, Adobe Illustrator/Photoshop, Figma, UI/UX design, print/merch design, simple motion design/animation (After Effects, Photoshop), simple 3D (Cinema 4D)',
        specialty: 'Illustrator, Figma, merch design, cute/brutalist/isometric designs, web design',
        fun: 'DAY6, pH-1, films/shows (any genre!), baking',
        imgSize: '',
        textSize: '',
        symbol: '1',
        gender: 'her',
    },
    {
        name: 'Juna',
        color: 'orange',
        experience: 'Graphic/visual/brand design, illustration, animation, UI/UX design, Adobe Illustrator/Photoshop/Animate, Figma, Procreate, print/merch design, animation',
        specialty: 'Illustrator, Photoshop, Figma, vector illustration, animation, print design',
        fun: 'Genshin Impact c;, Twitter/Instagram artists, Vtubers (NijiEN)',
        imgSize: 'med',
        textSize: '',
        symbol: '2',
        gender: 'her',
    },
    {
        name: 'Thoa',
        color: 'pink',
        experience: 'Graphic/visual/brand design, illustration, UI/UX design, Figma, Adobe Illustrator/Photoshop, print design, Procreate',
        specialty: 'Visual/UI Design, graphic design, cute Illustrations',
        fun: 'Pokemon, N.Flying, best stationery supplies (pen and mechanical pencil recommendations), journaling, cognitive functions (personality types) & love languages',
        imgSize: '',
        textSize: 'small-text',
        symbol: '3',
        gender: 'her',
    },
    {
        name: 'Ricky',
        color: 'green',
        experience: 'Graphic/visual/brand design, Adobe Illustrator/Photoshop/InDesign, Figma, motion design/animation (After Effects), UI/UX design, print design',
        specialty: 'After Effects, Illustrator, Photoshop, vector calligraphy, trendy design style (overlays, textures, gradients)',
        fun: 'Alternative R&B music, Iced Americanos, instant photography, Nintendo, sustainable fashion',
        imgSize: 'sm',
        textSize: 'small-text',
        symbol: '4',
        gender: 'him',
    },
    {
        name: 'Leann',
        color: 'pink',
        experience: 'Web design, web development, design systems, visual design, illustration',
        specialty: 'Open source software (Inkscape, Krita, Gimp)',
        fun: 'Minecraft, art, aquariums',
        imgSize: '',
        textSize: '',
        symbol: '5',
        gender: 'her',
    },
    {
        name: 'Meshach',
        color: 'green',
        experience: 'Web development, web design, visual/graphic design, shirt design, social media graphics, logo design',
        specialty: 'Web development, web design, social media graphics, logo design, typography',
        fun: 'Art, film/tv (Wes Anderson, A24)',
        imgSize: '',
        textSize: '',
        symbol: '6',
        gender: 'him',
    },
    {
        name: 'Sabrina',
        color: 'orange',
        experience: 'UX/UI design, print design, Figma, Adobe Illustrator/Photoshop',
        specialty: 'Graphic design, product design',
        fun: 'Art museums, music concerts, films/tv-shows, doodling, matcha',
        imgSize: '',
        textSize: '',
        symbol: '7',
        gender: 'her',
    },
]